.mini-calendar .fc-timegrid-slot-label {
    //display: none;
}


.mini-calendar .fc-timegrid-slots tr {
    height: 25px;
}

/* Augmenter la visibilité des bordures de la grille pour toutes les vues */
.mini-calendar .fc .fc-daygrid-day {
    border: 1px solid #DDD; /* Rend les bordures des jours plus épaisses et plus visibles */
}

.mini-calendar .fc .fc-timegrid-slot {
    border-top: 1px solid #DDD; /* Rend les lignes horaires plus épaisses et plus visibles */
}


/* Modifier la couleur de fond des jours inactifs à une couleur plus sombre */
.mini-calendar .fc .fc-nonbusiness {
    background-color: #444; /* Couleur plus sombre pour les jours inactifs */
}

.mini-calendar {
    max-height: 320px;
    overflow: hidden;
    boxShadow: 0 0 10px rgba(0,0,0,0.5)
}

.btn-primary, .fc-today-button{
    background-color: #fdb913 !important;
    border-color: #fdb913 !important;;
    color: #fff;
}

.fc-today-button {
    background-color: #4CAF50; /* Couleur de fond verte */
    color: white; /* Couleur du texte blanche */
}

.mini-calendar .fc-event {
    background-color: #fdb913;
    color: black;
    height: 20px;
    font-size: 10px;
    padding: 2px;
    margin-top: 3px;
}

.mini-calendar .fc-event i {
    margin-left: -100px;
    margin-top: 13px !important;
}

.bloc-rdv {
    padding: 20px
}

.bloc-rdv h5 {
    padding-bottom: 20px
}


.bloc-rdv ul li {
    text-align: left;
}

.css-1nmdiq5-menu {
    background-color: white !important;
}

.fc-timegrid-slot-minor {
    border-top: 1px solid white !important;
}